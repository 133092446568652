// THEME PROVIDER

import ThemeProvider from "@/theme/theme-provider";
// PRODUCT CART PROVIDER

import CartProvider from "@/contexts/CartContext";
// SITE SETTINGS PROVIDER

import SettingsProvider from "@/contexts/SettingContext";
// GLOBAL CUSTOM COMPONENTS

import ProgressBar from "@/components/progress";
import Header from "@/components/header/index.js";
import {SearchInputWithCategory} from "@/components/search-box/index.js";
import {useCallback, useEffect, useState} from "react";
import Sticky from "@/components/sticky/index.js";
import Topbar from "@/components/topbar/index.js";
import {Navbar} from "@/components/navbar/index.js";
import {Footer1} from "@/components/footer";
import {usePage} from "@inertiajs/react";

export default ({children, categories, query, category, auth, canResetPassword}) => {
    const { notifications } = usePage().props
    console.log(notifications)
    const [isFixed, setIsFixed] = useState(false);
    const toggleIsFixed = useCallback(fixed => setIsFixed(fixed), []);
    return (
        <div style={{height: '100vh', display: 'flex', flexDirection: 'column'}}>
            {/*<CartProvider>*/}
            {/*    <SettingsProvider>*/}
                    <ThemeProvider>
                        <Topbar/>
                        <Sticky fixedOn={0} onSticky={toggleIsFixed} scrollDistance={300}>
                            <Header categories={categories} isFixed={isFixed} auth={auth} canResetPassword={canResetPassword}
                                    midSlot={<SearchInputWithCategory categories={categories} category={category}
                                                                      query={query?.q}/>}/>
                        </Sticky>
                        <Navbar categories={categories} elevation={0} border={1} user={auth.user}/>
                        <div style={{flex: 1}}>{children}</div>
                        <Footer1/>
                    </ThemeProvider>
            {/*    </SettingsProvider>*/}
            {/*</CartProvider>*/}
        </div>
    )

}


